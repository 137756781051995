import { combineReducers } from 'redux'

import { Action } from '../store'
import Subscription from '../subscriptions/Subscription'
import { buildActions, createAction } from '../util/actions'

interface User {
  id: string
  config: {
    subscriptions?: Subscription[]
    // date config was last changed, added by server on successful config modifying PUT
    lastChanged?: string
  }
}

interface UserPasswordData {
  username: string
  password: string
  // false to login
  register: boolean
}

export interface GotUserPayload {
  user: User
  login: boolean
}

export const [userActions, isUserAction] = buildActions({
  // `login` should be `true` for the first page load after a registration or login
  gotUser: createAction('[User] got user')<GotUserPayload>(),

  // when it has been detected the user is not logged in
  gotNoUser: createAction('[User] got no user')(),

  logout: createAction('[User] logout')(),
  // payload is "true" to register, "false" to login
  tryPasswordAuth: createAction('[User] try password auth')<UserPasswordData>(),
  // error message payload
  passwordAuthFailure: createAction('[User] password auth failure')<string>(),
})

export interface UserState {
  me: User | null
  pendingAuth: boolean
  authError: string | null
}

export default combineReducers<UserState, Action>({
  me: (state = null, action: Action) => {
    if (isUserAction.gotUser(action)) {
      return action.payload.user
    } else if (isUserAction.logout(action)) {
      return null
    } else {
      return state
    }
  },

  pendingAuth: (state = true, action: Action) => {
    if (isUserAction.tryPasswordAuth(action)) {
      return true
    } else if (isUserAction.gotUser(action) || isUserAction.passwordAuthFailure(action)) {
      return false
    } else if (isUserAction.gotNoUser(action)) {
      return false
    } else {
      return state
    }
  },

  authError: (state = null, action: Action) => {
    if (isUserAction.tryPasswordAuth(action) || isUserAction.gotUser(action)) {
      return null
    } else if (isUserAction.passwordAuthFailure(action)) {
      return action.payload
    } else {
      return state
    }
  },
})
