import { Face } from '@material-ui/icons'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { MainBody } from '../shared/MainBody'

import fIcon from './f-icon.svg'

const iconWidth = '1.5rem'

const AuthBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > div {
    min-width: 19rem;
    background-color: white;
    padding: 2rem;
  }

  h2 {
    margin: 0 0 1rem;
    padding: 0;
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;

    li,
    a {
      display: flex;
      padding: 1rem 0.3rem;
      height: 3.2rem;
      flex-grow: 1;
      align-items: center;
    }

    li + li {
      margin-top: 0.7rem;
    }

    a {
      svg,
      img {
        height: ${iconWidth};
        width: ${iconWidth};
        margin-left: 0.3rem;
      }

      span {
        text-align: center;
        flex-grow: 1;
      }
    }
  }
`

const FacebookLink = styled.a`
  background-color: #3c5a99;
  color: white;

  &:hover {
    background-color: #325091;
  }

  img {
    position: relative;
    top: -0.1rem;
  }
`

const PasswordLink = styled(Link)`
  background-color: #ffc0cb;

  &:hover {
    background-color: #fab0bb;
  }
`

const authUrl = `${process.env.REACT_APP_API}/user/auth/facebook`

export const AuthForm: React.SFC<{ register?: boolean }> = ({ register }) => {
  const type = register ? 'register' : 'login'
  const heading = register ? 'Register' : 'Login'
  return (
    <MainBody>
      <AuthBody>
        <div>
          <h2>{heading}</h2>
          <ul>
            <li>
              <FacebookLink href={`${authUrl}${register ? '?register' : ''}`}>
                <img src={fIcon} />
                <span>Connect with facebook</span>
              </FacebookLink>
            </li>
            <li>
              <PasswordLink to={`/${type}/password`}>
                <Face />
                <span>{heading} with password</span>
              </PasswordLink>
            </li>
          </ul>
        </div>
      </AuthBody>
    </MainBody>
  )
}
