import { groupBy } from 'ramda'
import { createSelector } from 'reselect'

import { State } from '../root.reducer'
import { Source } from '../sources/Source'

interface SubscriptionConfig {
  color: string
}

export default interface Subscription {
  id: string
  source: Source
  config: SubscriptionConfig
  // date as string
  lastChanged: string
}

export interface SubscriptionsById {
  [id: string]: Subscription[]
}

export const selectSubscriptionsById = createSelector(
  (state: State) => state.subscriptions.list,
  groupBy(s => s.id),
)

export const selectHasNoSubscriptions = createSelector(
  (state: State) => state.subscriptions.list,
  (state: State) => state.subscriptions.loaded,
  (list: Subscription[], loaded: boolean) => loaded && !list.length,
)
