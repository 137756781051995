// ramda's isn't typed right
function once<R>(fun: (...args: any[]) => R): (...args: any[]) => R {
  let called = false
  let ret: R | null = null
  return (...args: any[]) => {
    if (called) {
      return ret!
    }
    ret = fun(...args)
    called = true
    return ret
  }
}

export default once
