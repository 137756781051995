import * as React from 'react'
import styled from 'styled-components'

import { SourceLink } from '../sources/SourceComponent'
import { TimeContainer } from '../time/Time'

import SubscribedFeed from './FeedItem'

interface Props {
  feedItem: SubscribedFeed
  sourceView?: boolean
}

const FeedLink = styled.a`
  display: flex;
  width: 100%;

  article {
    flex-grow: 1;
    border-bottom: 1px solid #eee;

    a:hover {
      text-decoration: underline;
    }
  }
`

const FeedItemComponent: React.SFC<Props> = React.memo(props => {
  const {
    feedItem: {
      id,
      url,
      date,
      title,
      subscription: {
        source,
        config: { color },
      },
    },
    sourceView,
  } = props

  const sourceNameSpan = <span className="url">{source.name}</span>

  return (
    <li key={id}>
      <FeedLink style={{ borderLeft: `7px solid ${color}` }} href={url || id}>
        <article>
          <h2>
            <span className="type">{source.type}</span>
            <TimeContainer time={date} />
            {sourceView ? (
              sourceNameSpan
            ) : (
              <SourceLink source={source} subscribed>
                {sourceNameSpan}
              </SourceLink>
            )}
          </h2>
          <p>{title}</p>
        </article>
      </FeedLink>
    </li>
  )
})

export default FeedItemComponent
