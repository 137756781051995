import { combineReducers } from 'redux'

import { State } from '../root.reducer'
import { isRouterAction } from '../router/router.reducer'
import { Source } from '../sources/Source'
import { Action } from '../store'
import { buildActions, createAction } from '../util/actions'

export const [searchActions, isSearchAction] = buildActions({
  setTerms: createAction('[Search] set terms')<string>(),
  run: createAction('[Search] run')<string>(),
  results: createAction('[Search] got results')<Source[]>(),
  force: createAction('[Search] force')(),
})

export const isNavToSearch = (action: Action) =>
  isRouterAction.set(action) && action.payload.pathName.startsWith('/search')
    ? action.payload.pathName.replace(/^\/search\/?/, '')
    : undefined

export interface SearchState {
  searchString: string | null
  searchingFor: string | null
  results: Source[] | null
}

export default combineReducers<SearchState, Action>({
  searchString: (state = null, action: Action) => {
    const search = isNavToSearch(action)
    if (search !== undefined) {
      return search
    }
    return isSearchAction.setTerms(action) ? action.payload : state
  },

  searchingFor: (state = null, action: Action) => {
    const search = isNavToSearch(action)
    if (search !== undefined) {
      return search
    }
    return isSearchAction.run(action) ? action.payload : state
  },

  results: (state = null, action: Action) =>
    isSearchAction.results(action) ? action.payload : state,
})
