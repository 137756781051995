import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'

const mapFilter = <T, U>(mapFun: (val: T) => U | undefined) =>
  pipe(
    map(mapFun),
    filter((value?: U): value is U => value !== undefined),
  )

export default mapFilter
