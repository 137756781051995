import { combineReducers } from 'redux'

import { Action } from '../store'
import { buildActions, createAction } from '../util/actions'

export const [progressActions, isProgressAction] = buildActions({
  // increment the number of outstanding pieces of progress
  increment: createAction('[Progress] increment')<number>(),
  // mark one of the outstanding pieces of progress as complete
  decrement: createAction('[Progress] decrement')<number>(),
  // reset all progress
  reset: createAction('[Progress] reset')(),
})

export interface ProgressState {
  // increments every time increment() is called, reset() sets to 0
  totalCount: number
  // increments everytime decrement() is called, reset() sets to 0
  completeCount: number
}

export default combineReducers<ProgressState, Action>({
  totalCount(state = 0, action: Action) {
    if (isProgressAction.increment(action)) {
      return state + action.payload
    } else if (isProgressAction.reset(action)) {
      return 0
    } else {
      return state
    }
  },

  completeCount(state = 0, action: Action) {
    if (isProgressAction.decrement(action)) {
      return state + action.payload
    } else if (isProgressAction.reset(action)) {
      return 0
    } else {
      return state
    }
  },
})
