import { mapObjIndexed } from 'ramda'
import { isActionOf } from 'typesafe-actions'
import { AC } from 'typesafe-actions/dist/is-action-of'
export { createStandardAction as createAction } from 'typesafe-actions'

type ActionCreator = AC<{ type: string }>
interface ActionCreators {
  [k: string]: ActionCreator
}

type ActionTests<T extends ActionCreators> = {
  [k in keyof T]: (ac: { type: string }) => ac is ReturnType<T[k]>
}

export const buildActionTests = <T extends ActionCreators>(actions: T): ActionTests<T> =>
  mapObjIndexed((val: ActionCreator) => isActionOf(val), actions) as ActionTests<T>

export const buildActions = <T extends ActionCreators>(actions: T) =>
  [actions, buildActionTests(actions)] as [T, ActionTests<T>]
