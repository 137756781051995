import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store as ReduxStore,
} from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { ActionType } from 'typesafe-actions'

import { rootActions, State } from './root.reducer'

import { feedEpic } from './feed/feed.effects'
import feedReducer, { feedActions } from './feed/feed.reducer'
import { progressEpic } from './progress/progress.effects'
import progressReducer, { progressActions } from './progress/progress.reducer'
import { routerEpic } from './router/router.effects'
import routerReducer, { routerActions } from './router/router.reducer'
import { searchEpic } from './search/search.effects'
import searchReducer, { searchActions } from './search/search.reducer'
import { subscriptionsEpic } from './subscriptions/subscription.effects'
import subscriptionsReducer, { subscriptionActions } from './subscriptions/subscription.reducer'
import timeReducer, { timeActions, timeEpic } from './time/time.reducer'
import { userEpic } from './user/user.effects'
import userReducer, { userActions } from './user/user.reducer'

export type Action = ActionType<
  | typeof rootActions
  | typeof searchActions
  | typeof subscriptionActions
  | typeof routerActions
  | typeof timeActions
  | typeof feedActions
  | typeof userActions
  | typeof progressActions
>

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// update redux store with router state
const rootEpic = combineEpics(
  routerEpic,
  timeEpic,
  searchEpic,
  subscriptionsEpic,
  feedEpic,
  userEpic,
  progressEpic,
)

const rootReducer = combineReducers<State, Action>({
  router: routerReducer,
  time: timeReducer,
  search: searchReducer,
  subscriptions: subscriptionsReducer,
  feed: feedReducer,
  user: userReducer,
  progress: progressReducer,
})

export const makeStore = () => {
  const epicMiddleware = createEpicMiddleware<Action, Action, State>()
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)))
  epicMiddleware.run(rootEpic)
  return store
}

export type Store = ReduxStore<State, Action>
