import { combineReducers } from 'redux'

import { Action } from '../store'
import { isSubscriptionAction } from '../subscriptions/subscription.reducer'
import { isUserAction } from '../user/user.reducer'
import { buildActions, createAction } from '../util/actions'

import { FeedItem, StoreFeedItem } from './FeedItem'

export const [feedActions, isFeedAction] = buildActions({
  set: createAction('[Feed] set')<StoreFeedItem[]>(),
  setExtra: createAction('[Feed] set extra')<FeedItem[]>(),
})

export interface FeedState {
  // items from subscriptions
  items: StoreFeedItem[]
  // items needed temporarily that are from sources the user has not subscribed to
  extraItems: FeedItem[]
}

export default combineReducers<FeedState, Action>({
  items: (state: StoreFeedItem[] = [], action: Action) => {
    if (isSubscriptionAction.remove(action)) {
      return state.filter(feedItem => feedItem.subscriptionId !== action.payload)
    } else if (isUserAction.logout(action)) {
      return []
    } else {
      return isFeedAction.set(action) ? action.payload : state
    }
  },

  extraItems: (state: FeedItem[] = [], action: Action) => {
    return isFeedAction.setExtra(action) ? action.payload : state
  },
})
