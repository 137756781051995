import { createBrowserHistory } from 'history'
import { BrowserRouter } from 'react-router-dom'

// TODO: move to react-redux-router when it is ready, for now use this hack to expose
//       history globally
export const history = createBrowserHistory()

export default class KchompBrowserRouter extends BrowserRouter {
  public history = history
}
