import { Add, Remove } from '@material-ui/icons'
import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../root.reducer'
import { Source, subIdFromSource } from '../sources/Source'
import { subscriptionActions } from '../subscriptions/subscription.reducer'
import { getNextColor } from '../subscriptions/SubscriptionDb'
import { getNow } from '../util/dates'
import { ConnectProps } from '../util/types'

type Props = ConnectProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  source: Source
  subscribed: boolean
}

export class SubscribeButton extends React.PureComponent<Props> {
  public render() {
    return this.props.subscribed ? (
      <button onClick={this.unsubscribe}>
        <Remove />
      </button>
    ) : (
      <button onClick={this.subscribe}>
        <Add />
      </button>
    )
  }

  private subscribe = (event: React.SyntheticEvent) => {
    event.preventDefault()
    const { source } = this.props
    this.props.subscribeTo({
      source,
      lastChanged: getNow(),
      id: subIdFromSource(source),
      config: {
        // TODO: get next color
        color: getNextColor(this.props.subscriptions),
      },
    })
  }

  private unsubscribe = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.props.unsubscribeFrom(subIdFromSource(this.props.source))
  }
}

const mapStateToProps = (state: State) => ({
  subscriptions: state.subscriptions.list,
})

const mapDispatchToProps = {
  subscribeTo: subscriptionActions.add,
  unsubscribeFrom: subscriptionActions.remove,
}

export const SubscribeButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscribeButton)
