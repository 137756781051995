import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LoginDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 0.9rem;

  a {
    color: #666;
    font-size: 0.8rem;
    display: flex;
    align-items: center;

    &:hover {
      color: black;
    }
  }
`

export const LoginHeader: React.SFC<{}> = React.memo(() => (
  <LoginDiv className="login-header">
    <Link to="/register" title="register new account">
      register
    </Link>
    |
    <Link to="/login" title="login">
      login
    </Link>
  </LoginDiv>
))
