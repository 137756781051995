import { Close, Menu, Search } from '@material-ui/icons'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { State } from './root.reducer'
import { selectPageName } from './router/router.reducer'
import { ConnectProps } from './util/types'

const HelpParagraph = styled.p`
  margin: 0.5rem;

  svg {
    position: relative;
    color: #666;

    &:hover {
      color: black;
    }
  }
`

// casts to "any" here needed to style material icons with styled-components v4+
// error otherwise: Property 'ref' is missing in type '{}'.  TS2322
const StyledSearch = styled(Search as any)`
  top: 0.2rem;
`

const StyledMenu = styled(Menu as any)`
  top: 0.4rem;
`

const StyledClose = styled(Close as any)`
  top: 0.4rem;
`

type Props = ConnectProps<typeof mapStateToProps>

export const Welcome: React.SFC<Props> = React.memo(({ pageName }) => {
  return (
    <div>
      <HelpParagraph>
        {pageName !== 'menu'
          ? `kchomp helps you keep up with the things you love and share them with your followers. `
          : `This is the menu page, your subscriptions would be here if you had any. `}
        Please use the{' '}
        <Link to="/search">
          <StyledSearch />
        </Link>{' '}
        to search for websites, tv shows and subreddits and subscribe to them.
      </HelpParagraph>

      {pageName === 'menu' ? (
        <HelpParagraph>
          You can use{' '}
          <Link to="/">
            <StyledClose />
          </Link>{' '}
          to go back to the home page.
        </HelpParagraph>
      ) : (
        <HelpParagraph>
          You can use{' '}
          <Link to="/menu">
            <StyledMenu />
          </Link>{' '}
          to view your subscriptions.
        </HelpParagraph>
      )}
    </div>
  )
})

const mapStateToProps = (state: State) => ({
  pageName: selectPageName(state),
})

export const WelcomeContainer = connect(mapStateToProps)(Welcome)
