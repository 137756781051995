import { sortBy } from 'ramda'
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { concat, EMPTY, merge as observableMerge, Observable, of } from 'rxjs'
import {
  catchError,
  filter,
  first,
  map,
  merge,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'

import { progressActions } from '../progress/progress.reducer'
import { State } from '../root.reducer'
import { isRouterAction } from '../router/router.reducer'
import { fetchRedditFeed } from '../search/api/reddit'
import { fetchTvMazeFeed } from '../search/api/tv-maze'
import { fetchWebFeed } from '../search/api/webfeed'
import { SourceLocator } from '../sources/Source'
import { Action } from '../store'
import { selectSubscriptionsById } from '../subscriptions/Subscription'
import { getSubscriptionChanges } from '../subscriptions/subscription.effects'
import { isSubscriptionAction } from '../subscriptions/subscription.reducer'
import mapFilter from '../util/mapFilter'

import { feedActions } from './feed.reducer'
import { FeedItem, StoreFeedItem } from './FeedItem'

const getFeedItemsForSource = (source: SourceLocator): Observable<FeedItem[]> => {
  if (source.type === 'reddit') {
    return fetchRedditFeed(source)
  } else if (source.type === 'tvmaze') {
    return fetchTvMazeFeed(source)
  } else if (source.type === 'webfeed') {
    return fetchWebFeed(source)
  }

  console.log('TODO: get feed items for subscription to source', source)
  return of([] as FeedItem[])
}

const sortFeedItems = (nowTime: number) =>
  sortBy((val: StoreFeedItem) => Math.abs(val.date - nowTime))

const isNavToSource = (action: Action): SourceLocator | undefined => {
  if (!isRouterAction.set(action)) {
    return undefined
  }

  const { pathName } = action.payload
  if (!pathName.startsWith('/source')) {
    return undefined
  }

  const typeAndPath = pathName.replace(/^\/source\//, '')
  const slashIdx = typeAndPath.indexOf('/')
  return {
    type: typeAndPath.substr(0, slashIdx),
    id: decodeURIComponent(typeAndPath.substr(slashIdx + 1)),
  }
}

export const feedEpic = combineEpics(
  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) => {
    return getSubscriptionChanges(actions$, state$).pipe(
      map(({ added }) => added),
      merge(
        actions$.pipe(
          filter(isSubscriptionAction.load),
          map(action => action.payload),
        ),
      ),
      mergeMap(newSubscriptions => {
        if (!newSubscriptions.length) {
          return EMPTY
        }

        return concat(
          of(progressActions.increment(newSubscriptions.length)),
          observableMerge(
            ...newSubscriptions.map(subscription => {
              const feedItems$ = getFeedItemsForSource(subscription.source)
              return feedItems$.pipe(
                catchError(err => {
                  console.log('error fetching feed', subscription.source, err)
                  return []
                }),
                filter(arr => !!arr.length),
                map(feedItems =>
                  feedItems.map(feedProps => ({ ...feedProps, subscriptionId: subscription.id })),
                ),
              )
            }),
          ).pipe(
            filter(newFeedItems => !!newFeedItems.length),
            withLatestFrom(
              state$.pipe(
                map(state => ({
                  feedItems: state.feed.items,
                  subscriptionsById: selectSubscriptionsById(state),
                })),
              ),
            ),
            map(([newFeedItems, { feedItems, subscriptionsById }]) => {
              const subbedNewItems = newFeedItems.filter(
                feedItem => !!subscriptionsById[feedItem.subscriptionId],
              )

              return subbedNewItems.length
                ? sortFeedItems(Date.now())(feedItems.concat(newFeedItems))
                : feedItems
            }),
            switchMap(feedItems =>
              concat(of(progressActions.decrement(1)), of(feedActions.set(feedItems))),
            ),
          ),
        )
      }),
    )
  },

  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) => {
    return actions$.pipe(
      mapFilter(isNavToSource),
      switchMap((sourceLocator: SourceLocator) => {
        return state$.pipe(
          map(state => state.subscriptions),
          filter(subscriptions => subscriptions.loaded),
          first(),
          switchMap(subs => {
            if (subs.list.some(sub => sub.source.id === sourceLocator.id)) {
              return EMPTY
            } else {
              return getFeedItemsForSource(sourceLocator).pipe(
                catchError(err => {
                  console.log('error fetching feed', sourceLocator, err)
                  return []
                }),
                filter(arr => !!arr.length),
                map(sortFeedItems(Date.now())),
                map(feedActions.setExtra),
              )
            }
          }),
        )
      }),
    )
  },
)
