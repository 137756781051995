import { Location } from 'history'
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, ignoreElements, tap, withLatestFrom } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { isRootAction, rootActions, State } from '../root.reducer'
import { Action } from '../store'

import { history } from './KchompBrowserRouter'
import { routerActions, RouterState } from './router.reducer'

const locationToRouterState = ({ pathname, search }: Location): RouterState => {
  const state: RouterState = { pathName: pathname }
  const query = location.search
  if (query !== '') {
    state.queryString = query
      .substr(1)
      .split('&')
      .reduce(
        (qs, pair) => {
          const [key, codedValue] = pair.split('=')
          qs[key] = decodeURIComponent(codedValue)
          return qs
        },
        {} as { [k: string]: string },
      )
  }
  return state
}

export const routerEpic = combineEpics(
  // dispatch setPathname actions as url changes
  () => {
    return new Observable<Action>(observer => {
      observer.next(routerActions.set(locationToRouterState(history.location)))
      return history.listen((location: Location) => {
        observer.next(routerActions.set(locationToRouterState(history.location)))
      })
    })
  },

  // switch url between /search and / on toggleSearch action
  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) =>
    actions$.pipe(
      filter(isActionOf([rootActions.toggleSearch, rootActions.toggleMenu])),
      withLatestFrom(state$, (action, state) => ({
        router: state.router,
        isSearch: isRootAction.toggleSearch(action),
      })),
      tap(({ router, isSearch }) => {
        const { pathName } = router
        const newUrl = isSearch ? '/search' : '/menu'
        history.push(pathName.startsWith(newUrl) ? '/' : newUrl)
      }),
      ignoreElements(),
    ),
)
