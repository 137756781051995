import { combineReducers } from 'redux'
import { interval } from 'rxjs'
import { map } from 'rxjs/operators'

import { Action } from '../store'
import { buildActions, createAction } from '../util/actions'

export const [timeActions, isTimeAction] = buildActions({
  setNow: createAction('[Time] set now')(),
})

export const timeEpic = () => interval(60000).pipe(map(() => timeActions.setNow()))

export interface TimeState {
  now: number
}

export default combineReducers<TimeState, Action>({
  now: (current: number = Date.now(), action: Action) => {
    return isTimeAction.setNow(action) ? Date.now() : current
  },
})
