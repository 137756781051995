import { Close, Menu, Search } from '@material-ui/icons'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ProgressIndicatorContainer } from '../progress/ProgressIndicator'
import { rootActions, State } from '../root.reducer'
import { selectPageName } from '../router/router.reducer'
import { ConnectProps } from '../util/types'

import { LoginHeader } from './LoginHeader'
import mainLogo from './logo.svg'
import { MenuHeaderContainer } from './MenuHeader'

const headerHeight = '2.5rem'

const HeaderEl = styled.header`
  position: sticky;
  top: 0;

  div.main-header {
    display: flex;
    height: ${headerHeight};
    background-color: #fff;

    button,
    .menu-header a,
    .login-header a {
      padding: 0 0.3rem;
    }

    svg {
      height: ${headerHeight};
      width: ${headerHeight};
      padding: 0.2rem;
      color: #666;

      &:hover {
        color: black;
      }
    }
  }
`
const Img = styled.img`
  height: ${headerHeight};
  width: ${headerHeight};
`
const ToolsDiv = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0.2rem;
`

const Title = styled.div`
  display: flex;
  margin: 0 0 0 0.5rem;
  align-items: center;
  font-size: 1rem;
  padding: 0;

  ul {
    display: inline-flex;
  }
`

const renderTitleMenu = (pageName: string, loggedIn: boolean, pendingAuth: boolean) => {
  if (pageName === 'search') {
    // TODO: SearchFormContainer should go here
    return 'Search'
  } else if (pageName === 'menu' || pageName === 'register' || pageName === 'login') {
    return <MenuHeaderContainer />
  } else {
    return pendingAuth || loggedIn ? null : <LoginHeader />
  }
}

type Props = ConnectProps<typeof mapStateToProps, typeof mapDispatchToProps>

export const Header: React.SFC<Props> = React.memo(
  ({ toggleSearch, toggleMenu, pageName, loggedIn, pendingAuth }) => (
    <HeaderEl>
      <div className="main-header">
        <Link to="/">
          <Img src={mainLogo} />
        </Link>
        <Title>{renderTitleMenu(pageName, loggedIn, pendingAuth)}</Title>
        <ToolsDiv>
          <button title="search" onClick={toggleSearch}>
            {pageName === 'search' ? <Close /> : <Search />}
          </button>
          <button onClick={toggleMenu}>{pageName === 'menu' ? <Close /> : <Menu />}</button>
        </ToolsDiv>
      </div>
      <ProgressIndicatorContainer />
    </HeaderEl>
  ),
)

const mapStateToProps = (state: State) => ({
  pageName: selectPageName(state),
  loggedIn: !!state.user.me,
  pendingAuth: state.user.pendingAuth,
})

const mapDispatchToProps = {
  toggleSearch: rootActions.toggleSearch,
  toggleMenu: rootActions.toggleMenu,
}

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
