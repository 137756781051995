import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../root.reducer'
import { selectPageName } from '../router/router.reducer'
import FancyList from '../shared/FancyList'
import { MainBody } from '../shared/MainBody'
import { selectHasNoSubscriptions } from '../subscriptions/Subscription'
import { ConnectProps } from '../util/types'
import { VList } from '../vlist/VList'
import { WelcomeContainer } from '../Welcome'

import FeedItem, { selectRelevantFeedItems } from './FeedItem'
import FeedItemComponent from './FeedItemComponent'

type Props = ConnectProps<typeof mapStateToProps, void>

const renderFeedItem = (sourceView: boolean, feedItem: FeedItem) => {
  return <FeedItemComponent key={feedItem.id} feedItem={feedItem} sourceView={sourceView} />
}

const getId = (feedItem: FeedItem) => feedItem.id

const Feed: React.SFC<Props> = React.memo(({ feedItems, pageName, hasNoSubscriptions }) => {
  const sourceView = pageName === 'source'

  if (!sourceView && hasNoSubscriptions) {
    return (
      <MainBody>
        <WelcomeContainer />
      </MainBody>
    )
  }

  return (
    <MainBody>
      <VList
        List={FancyList}
        items={feedItems}
        getId={getId}
        renderItem={renderFeedItem.bind(null, sourceView)}
      />
    </MainBody>
  )
})

const mapStateToProps = (state: State) => ({
  feedItems: selectRelevantFeedItems(state),
  pageName: selectPageName(state),
  hasNoSubscriptions: selectHasNoSubscriptions(state),
})

export const FeedContainer = connect(
  mapStateToProps,
  null,
)(Feed)
