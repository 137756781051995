import { ExitToApp } from '@material-ui/icons'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../root.reducer'
import { userActions } from '../user/user.reducer'
import { ConnectProps } from '../util/types'

import { LoginHeader } from './LoginHeader'

const FlexDiv = styled.div`
  display: flex;
  height: 100%;

  a {
    display: flex;
    align-items: center;
  }
`

type Props = ConnectProps<typeof mapStateToProps, typeof mapDispatchToProps>

// TODO: grey out active link
export const MenuHeader: React.SFC<Props> = ({ user, pendingAuth, logout }) => (
  <FlexDiv className="menu-header">
    {user || pendingAuth ? null : <LoginHeader />}
    {!user ? null : (
      <button onClick={logout}>
        <ExitToApp />
      </button>
    )}
  </FlexDiv>
)

const mapStateToProps = (state: State) => ({
  user: state.user.me,
  pendingAuth: state.user.pendingAuth,
})

const mapDispatchToProps = {
  logout: userActions.logout,
}

export const MenuHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuHeader)
