import { isAfter } from 'date-fns'
import { differenceWith, equals } from 'ramda'
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { combineLatest, concat, EMPTY, from, of, pipe } from 'rxjs'
import {
  concatMap,
  filter,
  first,
  ignoreElements,
  map,
  pairwise,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { State } from '../root.reducer'
import { Action } from '../store'
import { GotUserPayload, isUserAction, userActions } from '../user/user.reducer'
import { unimportantApiCall } from '../util/api'
import { earliestTime, getLatestOf } from '../util/dates'
import once from '../util/once'

import Subscription from './Subscription'
import { isSubscriptionAction, subscriptionActions } from './subscription.reducer'
import SubscriptionDb from './SubscriptionDb'

const db = new SubscriptionDb()

const compareSubs = (a: Subscription, b: Subscription) => a.id === b.id

export const getSubscriptionChanges = once(
  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) =>
    actions$.pipe(
      filter(
        isActionOf([
          subscriptionActions.add,
          subscriptionActions.remove,
          subscriptionActions.set,
          userActions.logout,
        ]),
      ),
      withLatestFrom(
        state$.pipe(
          map(state => state.subscriptions.list),
          pairwise(),
        ),
        (_, states) => states,
      ),
      map(([prevSubs, subs]) => {
        // TODO: handle reordering
        const added = differenceWith(compareSubs, subs, prevSubs)
        const removed = differenceWith(compareSubs, prevSubs, subs)
        return { added, removed }
      }),
      shareReplay(1),
    ),
)

const saveSubscriptionsToServer = (subs: Subscription[]) =>
  unimportantApiCall('/user/me/subscriptions', subs)

export const subscriptionsEpic = combineEpics(
  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) =>
    getSubscriptionChanges(actions$, state$).pipe(
      concatMap(async ({ added, removed }) => {
        if (removed.length) {
          await db.delete(removed.map(sub => sub.id))
        }

        if (added.length) {
          // console.debug('adding subscriptions', added)
          await db.add(added)
        }
      }),
      ignoreElements(),
    ),

  () => from(db.load()).pipe(map(subs => subscriptionActions.load(subs))),

  // this pipe compares the user's config saved on the server with the subscriptions
  // stored locally and merges changes each way as necessary
  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) => {
    return concat(
      // first send the user/subscriptions after they've both been loaded
      combineLatest(
        actions$.pipe(filter(isUserAction.gotUser)),
        actions$.pipe(filter(isSubscriptionAction.load)),
        (userAction, _) => userAction.payload,
      ).pipe(first()),

      // then send all future user changes
      actions$.pipe(
        filter(isUserAction.gotUser),
        map(action => action.payload),
      ),
    ).pipe(
      withLatestFrom(state$, (gotUserPayload: GotUserPayload, state) => ({
        ...gotUserPayload,
        subscriptions: state.subscriptions.list,
      })),

      switchMap(({ user, login, subscriptions: clientSubs }) => {
        const serverSubs = user.config.subscriptions || []
        if (equals(serverSubs, clientSubs)) {
          return EMPTY
        }

        // TODO: merge subscriptions between server/user, until that happens
        // changes will get lost if the user modifies the state on two different computers
        // that are both offline
        const latestClientChange = getLatestOf(clientSubs.map(sub => sub.lastChanged))
        const latestServerChange = user.config.lastChanged || earliestTime

        if (!(login && serverSubs.length) && isAfter(latestClientChange, latestServerChange)) {
          console.debug('send subscriptions to server')
          return saveSubscriptionsToServer(clientSubs)
        } else {
          console.debug('overwrite local subscriptions with those from server')
          // console.debug(clientSubs, serverSubs)
          return of(subscriptionActions.set(serverSubs))
        }
      }),
    )
  },

  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) =>
    actions$.pipe(
      filter(isActionOf([subscriptionActions.add, subscriptionActions.remove])),
      withLatestFrom(state$, (_, state) => state.user.me && state.subscriptions.list),
      switchMap(subs => (subs ? saveSubscriptionsToServer(subs) : EMPTY)),
    ),
)
