import { createSelector } from 'reselect'
import { createArraySelector } from 'reselect-map'

import { State } from '../root.reducer'
import { RouterState } from '../router/router.reducer'
import { Source } from '../sources/Source'
import Subscription, {
  selectSubscriptionsById,
  SubscriptionsById,
} from '../subscriptions/Subscription'
import { getNow } from '../util/dates'

export interface FeedItem {
  id: string
  // url when the id is not the url
  url?: string
  title: string
  date: number
}

// all dates are ISO format
export interface StoreFeedItem extends FeedItem {
  subscriptionId: string
}

export default interface SubscribedFeed extends FeedItem {
  subscription: Subscription
}

const sourcePath = '/source/'

export const selectFeedItems = createArraySelector(
  (state: State) => state.feed.items,
  selectSubscriptionsById,
  (storeFeedItem: StoreFeedItem, subscriptions: SubscriptionsById): SubscribedFeed => {
    const { subscriptionId, ...props } = storeFeedItem
    const subscription = subscriptions[subscriptionId][0]
    if (!subscription) {
      throw new Error(
        `feed item using subscription id ${subscriptionId} which was not subscribed to`,
      )
    }
    return { ...props, subscription }
  },
)

export const selectRelevantFeedItems = createSelector(
  selectFeedItems,
  (state: State) => state.router,
  (state: State) => state.feed.extraItems,
  (state: State) => state.subscriptions.list,

  (
    feedItems: SubscribedFeed[],
    { pathName, queryString }: RouterState,
    extraItems: FeedItem[],
    subscriptions: Subscription[],
  ) => {
    if (!pathName.startsWith(sourcePath)) {
      return feedItems
    }

    const [sourceType, codedSourceId] = pathName.replace(sourcePath, '').split('/')
    const sourceId = decodeURIComponent(codedSourceId)
    if (!subscriptions.some(({ source }) => source.id === sourceId)) {
      const name =
        sourceType === 'reddit'
          ? sourceId.replace(/\/$/, '')
          : (queryString && queryString.name) || sourceId
      const subscription: Subscription = {
        id: 'tmp',
        config: { color: '#ddd' },
        lastChanged: getNow(),
        source: {
          id: sourceId,
          name,
          type: sourceType,
        } as Source,
      }
      return extraItems.map(feedProps => ({ ...feedProps, subscription }))
    } else {
      return feedItems.filter(feedItem => feedItem.subscription.source.id === sourceId)
    }
  },
)
