import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../root.reducer'
import FancyList from '../shared/FancyList'
import { MainBody } from '../shared/MainBody'
import { Source, subIdFromSource } from '../sources/Source'
import SourceComponent from '../sources/SourceComponent'
import { selectSubscriptionsById } from '../subscriptions/Subscription'
import { ConnectProps } from '../util/types'

import { SearchFormContainer } from './SearchForm'

type Props = ConnectProps<typeof mapStateToProps>

export class Search extends React.PureComponent<Props> {
  public render() {
    return (
      <MainBody>
        <SearchFormContainer />
        <FancyList>
          {this.props.searchResults && this.props.searchResults.map(this.renderSearchResult)}
        </FancyList>
      </MainBody>
    )
  }

  private renderSearchResult = (source: Source) => {
    const subscription = this.props.subscriptionsById[subIdFromSource(source)]
    return (
      <SourceComponent
        color={subscription && subscription[0].config.color}
        key={source.id}
        source={source}
        subscribed={!!subscription}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  searchResults: state.search.results,
  subscriptionsById: selectSubscriptionsById(state),
})

export const SearchContainer = connect(mapStateToProps)(Search)
