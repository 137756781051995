import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { concat, of } from 'rxjs'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  merge,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import { State } from '../root.reducer'
import { history } from '../router/KchompBrowserRouter'
import { Action } from '../store'
import mapFilter from '../util/mapFilter'

import runSearch from './runSearch'
import { isNavToSearch, isSearchAction, searchActions } from './search.reducer'

export const searchEpic = combineEpics(
  // search for stuff on searchFor action and navigations to /search/:term
  (actions: ActionsObservable<Action>, state$: StateObservable<State>) => {
    return actions.pipe(
      filter(isSearchAction.setTerms),
      debounceTime(300),
      map(action => action.payload),
      merge(actions.pipe(mapFilter(isNavToSearch))),
      distinctUntilChanged(),
      switchMap(search => {
        if (!search) {
          return of(searchActions.results([]))
        }

        return concat(of(searchActions.run(search)), runSearch(search))
      }),
    )
  },

  // update url on forceSearch action
  (actions: ActionsObservable<Action>, state$: StateObservable<State>) => {
    return actions.pipe(
      filter(isSearchAction.force),
      withLatestFrom(state$, (_, state) => state),
      tap(state => {
        history.push(`/search/${state.search.searchString}`)
      }),
      ignoreElements(),
    )
  },
)
