import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { concat, EMPTY, Observable, of, throwError } from 'rxjs'
import {
  catchError,
  delay,
  endWith,
  filter,
  map,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { State } from '../root.reducer'
import { Action } from '../store'

import { progressActions } from './progress.reducer'

// after `showCompleteDuration`ms the 100% complete progress bar is removed
const showCompleteDuration = 1000

type ProgressIncrementOrDecrement =
  | ReturnType<typeof progressActions.increment>
  | ReturnType<typeof progressActions.decrement>

export const progressOp = () => {
  return <T>(source: Observable<T>): Observable<T | ProgressIncrementOrDecrement> => {
    return source.pipe(
      catchError(err => concat(of(progressActions.decrement(1)), throwError(err))),
      startWith(progressActions.increment(1) as T | ProgressIncrementOrDecrement),
      endWith(progressActions.decrement(1) as T | ProgressIncrementOrDecrement),
    )
  }
}

export const progressEpic = combineEpics(
  // issue a reset() action after the progress has been full for `showCompleteDuration`
  (actions$: ActionsObservable<Action>, state$: StateObservable<State>) => {
    return actions$.pipe(
      filter(isActionOf([progressActions.increment, progressActions.decrement])),
      withLatestFrom(state$, (_, state) => state.progress),
      map(({ totalCount, completeCount }) => totalCount > 0 && completeCount === totalCount),
      switchMap(
        (complete: boolean) =>
          complete ? of(progressActions.reset()).pipe(delay(showCompleteDuration)) : EMPTY,
      ),
    )
  },
)
