import { empty, Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { FeedItem } from '../../feed/FeedItem'
import { SourceLocator, SourceWebFeed } from '../../sources/Source'
import { rxFetch } from '../../util/fetch'

const matchDomain = /^(https?:\/\/)?((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})/i

export const searchWebFeeds = (search: string) => {
  if (!matchDomain.test(search)) {
    return empty()
  }

  return rxFetch(`${process.env.REACT_APP_API}/feeds`, { search }).pipe(
    map((json: any[]) => {
      return json.map(
        ({ title, url }): SourceWebFeed => {
          return {
            type: 'webfeed',
            name: title,
            id: url,
          }
        },
      )
    }),
    catchError(() => empty()),
  )
}

export const fetchWebFeed = (sourceLocator: SourceLocator): Observable<FeedItem[]> =>
  rxFetch(`${process.env.REACT_APP_API}/feeds`, { url: sourceLocator.id }).pipe(
    map(feedResponse => {
      try {
        return feedResponse.map((response: any) => {
          const { id, title, date } = response
          return { id, title, date }
        })
      } catch (e) {
        console.warn('issue parsing webfeed posts', e)
        return []
      }
    }),
  )
