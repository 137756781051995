import styled from 'styled-components'

const FancyList = styled.ul`
  &:empty {
    outline: 0;
  }

  li {
    display: flex;
    background-color: white;

    &:hover {
      background-color: #ffefd5;
    }

    article {
      padding: 0.4rem 0 0.4rem 0.7rem;
    }
  }

  h2 {
    font-size: 0.7rem;
    margin: 0;
    margin-top: 0.2rem;

    .type,
    time {
      margin-right: 0.5rem;
    }
  }

  p {
    margin: 0.3rem 0 0;
  }

  button {
    flex-shrink: 0;
    margin-left: auto;
    width: 2.7rem;

    &:hover {
      background-color: #f0f0f0;

      svg {
        color: black;
      }
    }
  }

  svg {
    height: 2rem;
    width: 2rem;
    color: #666;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export default FancyList
