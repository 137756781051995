const colors = Object.freeze([
  '#d15730',
  '#599635',
  '#556a75',
  '#c14d98',
  '#d7b73a',
  '#523565',
  '#62d89d',
  '#6c8ec9',
  '#6d282e',
  '#d04062',
  '#517549',
  '#cfaece',
  '#c1d776',
  '#bd7680',
  '#d3a67c',
  '#b2ccab',
  '#7f6ccd',
  '#8d672a',
  '#3a3627',
  '#86df47',
  '#79c9d3',
  '#cd54d7',
])

export default colors
