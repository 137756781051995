import { throttle } from 'lodash-es'
import * as React from 'react'

interface Props<D, P> {
  List: React.ComponentType<{
    children?: React.ReactNode
    style: React.CSSProperties
    ref: React.Ref<any>
  }>
  items: D[]
  renderItem: (item: D) => React.ReactElement<P>
  getId: (item: D) => string

  // items that fill `bufferSize` pixels will be displayed either side of the visible elements
  bufferSize: number

  // how many items to render at a time when calculating the draw state
  // TODO: only use this as a hint for the first redraw and from there estimate
  // the next chunk size using the average list item height
  renderChunkSize: number

  // how often to update the list while scrolling
  scrollThrottleInterval: number
}

const fallbackScrollHeight = 2000

const calculateScrollHeight = (el?: HTMLElement) =>
  el
    ? window.innerHeight - (el.getBoundingClientRect().top + window.scrollY)
    : fallbackScrollHeight

class ListState {
  public status = Calculated.Nothing

  // number of "skipped" elements at head of list, in progress until status >= Skipped
  public nSkipped = 0

  // total height calculated so far, including skipped and rendered elements
  public calculatedHeight = 0

  // number rendered, including the top and bottom buffers
  public nRendered = 0

  get nSkippedAndRendered() {
    return this.nSkipped + this.nRendered
  }
}

interface RenderState<P> {
  // getItem(item) or corresponding item
  id: string
  // either current height or last known height
  height: number
  // when true height could be the stale (in which case height represents the
  // last known height)
  stale: boolean
  // the element
  element: React.ReactElement<P> | undefined
}

type ItemStates<P> = Array<RenderState<P> | undefined>

interface State<D, P> {
  // total height of skipped elements, unknown until listState.status >= TopBuffer
  skippedHeight: number

  scrollHeight: number
  // items are rendered: listState.nSkipped <= items < toIdx
  toIndex: number

  // stores list-related state, this is essentially a cache used to speed up the
  // calculations and is mutated directly rather than being functionally reset
  listState: ListState

  // a list of the item states in order of props.item, list listState this is also
  // a cache used for performance reasons and is mutated
  itemStates: ItemStates<P>

  // a copy of the last known props.items, needed so getDerivedStateFromProps() can tell
  // if it has changed
  prevItems: D[] | undefined

  // reference to list element
  listRef: React.RefObject<{}>

  // the maximum height calculated so far
  maxCalculatedHeight: number
  // the number of items factored into the max calculated height
  nCalculated: number
}

// represents how much of the draw state is fully calculated
enum Calculated {
  Nothing = 0,
  Skipped,
  Rendered,
}

// Mutate state.listState according to changes between prevItems and items
// returns true if the calculated height is less than before. When this function
// returns true one or both of skippedHeight and calculatedHeight will be out of
// date
function updateListStateStatusForItemChanges<D, P>(items: D[], state: State<D, P>): boolean {
  const { listState } = state
  const prevItems = state.prevItems!

  // TODO: should probably be looking at those with known heights rather than those
  // that are unchanged
  let i = 0
  for (; i < items.length && items[i] === prevItems[i]; ++i) {}
  const nUnchanged = i

  if (nUnchanged < listState.nSkipped) {
    // the skipped count is partially calculated or not calculated
    listState.status = Calculated.Nothing
    listState.nSkipped = nUnchanged
    // listState.skippedHeight is now out of date, after the other "return true"
    // both skippedHeight and calculatedHeight will be out of date
    listState.nRendered = 0
    return true
  } else if (listState.status < Calculated.Skipped) {
    return true
  }

  const unchangedAfterSkipped = nUnchanged - listState.nSkipped
  if (unchangedAfterSkipped < listState.nRendered) {
    // only the skipped count has been fully calculated
    listState.status = Calculated.Skipped
    listState.nRendered = unchangedAfterSkipped
    return true
  } else {
    return false
  }
}

function updateListStateForItemChanges<D, P>(items: D[], state: State<D, P>) {
  const { listState } = state
  let { skippedHeight } = state
  if (updateListStateStatusForItemChanges(items, state)) {
    const { nRendered, nSkipped } = listState
    if (listState.status === Calculated.Nothing) {
      // when status is beyond Nothing, skippedHeight is fully calculated
      for (let i = 0; i < nSkipped; ++i) {
        skippedHeight += state.itemStates[i]!.height
      }
    }

    let calculatedHeight = skippedHeight
    const nRenderedAndSkipped = nRendered + nSkipped
    for (let i = nSkipped; i < nRenderedAndSkipped; ++i) {
      calculatedHeight += state.itemStates[i]!.height
    }
    listState.calculatedHeight = calculatedHeight
  }
  return skippedHeight
}

function updateItemStatesAfterListItemsUpdate<D, P>(props: Props<D, P>, state: State<D, P>) {
  const { items, getId } = props
  const {
    listState: { nSkippedAndRendered },
    itemStates,
    prevItems,
  } = state

  const prevStateMap = new Map<string, { item: D; itemState: RenderState<P> }>()

  // everything up to nSkippedAndRendered was copied from the previous list at this point,
  // store the rest in a map so the rest can be transferred as needed
  for (let i = nSkippedAndRendered; i < itemStates.length; ++i) {
    const itemState = itemStates[i]
    if (itemState) {
      prevStateMap.set(itemState.id, { item: prevItems![i], itemState })
    }
  }

  // the rest of the items are new elements mixed with old ones, copy the old
  // itemState entries across where appropriate and create entries for the new
  for (let i = nSkippedAndRendered; i < items.length; ++i) {
    const item = items[i]
    const prevItemState = prevStateMap.get(getId(item))
    if (!prevItemState) {
      itemStates[i] = undefined
    } else if (prevItemState.item === item) {
      itemStates[i] = prevItemState.itemState
    } else {
      itemStates[i] = {
        ...prevItemState.itemState,
        stale: true,
        element: undefined,
      }
    }
  }
  itemStates.splice(items.length)
}

// Returns a toIndex for the next render based on current state and ensures
// state.itemStates is set for those indexes
function getToIndexForNextRender<D, P>(
  props: Props<D, P>,
  state: State<D, P>,
  scrollHeight?: number,
): number {
  const { items, getId, renderChunkSize, bufferSize } = props
  const { itemStates, listState } = state
  if (scrollHeight === undefined) {
    scrollHeight = state.scrollHeight
  }

  if (!items.length) {
    console.debug(' - empty')
    return 0
  }

  if (listState.status === Calculated.Rendered) {
    console.debug(' - complete')
    return listState.nSkippedAndRendered
  }

  const { nSkippedAndRendered } = listState
  let { calculatedHeight } = listState
  let newlyRenderedItems = 0
  const visibleZoneEnd = window.scrollY + scrollHeight + bufferSize

  let i = nSkippedAndRendered
  for (
    const { length } = items;
    i < length && newlyRenderedItems < renderChunkSize && calculatedHeight < visibleZoneEnd;
    ++i
  ) {
    let itemState = itemStates[i]
    const item = items[i]

    if (!itemState) {
      itemState = itemStates[i] = {
        id: getId(item),
        height: 0,
        stale: false,
        element: undefined,
      }
    }

    if (!itemState.element) {
      itemState.element = props.renderItem(item)
      ++newlyRenderedItems
    } else {
      calculatedHeight += itemState.height
    }
  }

  console.debug(' - toIndex changed', i)
  return i
}

// the new state returned by processRenderState
interface RenderChanges {
  skippedHeight: number
  nCalculated: number
  maxCalculatedHeight: number
}

/**
 * Renders a virtual list. The rendered elements must:
 *  * Accept a `style` prop which is passed through to the created list element
 *
 * The render list looks something like this:
 *
 * 1. when nSkipped > 0: empty space representing "nSkipped" elements, the list
 *    will be given a "paddingTop" to represent this space
 * 2. when nTopBuffer > 0: elements not visible on the screen but which are being
 *    rendered
 * 3. when nVisible > 0: elements (at least partially) visible to the user
 * 4. when nBottomBuffer > 0: elements not visible on the screen but which
 *    are being rendered
 * 5. Empty space representing (possibly) known heights of elements at end
 */
export class VList<D, P> extends React.Component<Props<D, P>, State<D, P>> {
  public static defaultProps = {
    bufferSize: 800,
    renderChunkSize: 100,
    scrollThrottleInterval: 300,
  }

  public static getDerivedStateFromProps<D, P>(props: Props<D, P>, state: State<D, P>) {
    const listEl = state.listRef.current
    if (!listEl) {
      return null
    }

    const { prevItems } = state
    if (props.items === prevItems || (!props.items.length && (!prevItems || !prevItems.length))) {
      // if they are equal (or both empty/undefined) then there is no derived state
      return null
    }

    const { listState } = state
    const prevItemsCount = prevItems ? prevItems.length : 0
    if (prevItemsCount > 0) {
      const skippedHeight = updateListStateForItemChanges(props.items, state)
      updateItemStatesAfterListItemsUpdate(props, state)

      if (
        prevItemsCount === listState.nSkippedAndRendered &&
        prevItemsCount < props.items.length
      ) {
        // if the list grew when scrolled to the end then can't be sure status is complete
        // TODO: see comment in getToIndexForNextRender(), only set this lower if it
        // is calculated there is space within the buffer to show some of the new items
        state.listState.status = Calculated.Skipped
      }

      console.debug('get next state: get derived state with item change')
      return {
        skippedHeight,
        prevItems: props.items,
        // pass an overridded toIndex (the number of unchanged items at the
        // head), so that getStateForNextRender() can tell if the toIndex has
        // increased from its new position:
        toIndex: getToIndexForNextRender(props, state),
      }
    } else {
      console.debug('get next state: get derived state from empty')
      if (props.items.length > 0) {
        // if the list grew from empty then the list is no longer complete
        listState.status = Calculated.Skipped
      }

      return {
        prevItems: props.items,
        toIndex: getToIndexForNextRender(props, state),
      }
    }
  }

  private onScroll = throttle(() => {
    if (!this.getListEl()) {
      return
    }
    // TODO: only reset everything when scrolling up
    const { listState } = this.state
    listState.nSkipped = listState.nRendered = 0
    listState.calculatedHeight = 0
    listState.status = Calculated.Nothing

    console.debug('------------------------ processRenderState after scroll')

    // since toIdx is 0 the processing will stop when a stale/unprocessed
    // itemState is found, so it won't matter that what's rendered might not
    // have zero skipped elements (listState.nSkipped was reset to 0 above)
    // maxCalculatedHeight and nCalculated can't increase from this
    const { skippedHeight } = this.processRenderState(0, 0)

    console.debug('get next state: on scroll')
    this.setState({
      toIndex: getToIndexForNextRender(this.props, this.state),
      // will be recalculated by processRenderState
      skippedHeight,
    })
  }, this.props.scrollThrottleInterval)

  private onResizeHandler: (() => void) | undefined

  constructor(props: Props<D, P>) {
    super(props)
    this.state = {
      skippedHeight: 0,
      toIndex: 0,
      scrollHeight: 0,
      itemStates: [],
      listState: new ListState(),
      prevItems: [],
      listRef: React.createRef(),
      maxCalculatedHeight: 0,
      nCalculated: 0,
    }
  }

  public shouldComponentUpdate(_: any, prevState: State<D, P>) {
    const { state } = this
    return (
      state.listState.status !== Calculated.Rendered ||
      state.toIndex !== prevState.toIndex ||
      state.skippedHeight !== prevState.skippedHeight ||
      state.maxCalculatedHeight !== prevState.maxCalculatedHeight
    )
  }

  public render() {
    const { List } = this.props
    const {
      itemStates,
      listState,
      toIndex,
      skippedHeight,
      maxCalculatedHeight,
      nCalculated,
      listRef,
    } = this.state

    const childElements: Array<React.ReactElement<P>> = []
    for (let i = listState.nSkipped; i < itemStates.length && i < toIndex; ++i) {
      childElements.push(itemStates[i]!.element!)
    }

    const nItems = this.props.items.length
    const { calculatedHeight } = listState
    const fullHeight =
      nCalculated === nItems
        ? maxCalculatedHeight
        : maxCalculatedHeight + (maxCalculatedHeight / nCalculated) * (nItems - nCalculated)

    const paddingBottom = fullHeight ? `${fullHeight - calculatedHeight}px` : '0px'

    const spacingStyle = { paddingTop: `${skippedHeight}px`, paddingBottom }
    return (
      <List style={spacingStyle} ref={listRef}>
        {childElements}
      </List>
    )
  }

  public componentDidMount() {
    this.createResizeHandler()
    window.addEventListener('resize', this.onResizeHandler!)

    const scrollHeight = calculateScrollHeight(this.getListEl())
    console.debug('get next state: did mount')
    // getStateForNextRender() this will happen in getDerivedStateFromProps
    this.setState({ scrollHeight })

    window.addEventListener('scroll', this.onScroll)
  }

  public componentDidUpdate(_: Props<D, P>, prevState: State<D, P>) {
    const { state } = this
    if (state.listState.status === Calculated.Rendered) {
      return
    }

    console.debug(
      '------------------------ processRenderState from: ',
      state.listState.nRendered + state.listState.nSkipped,
    )
    const { skippedHeight, nCalculated, maxCalculatedHeight } = this.processRenderState(
      state.skippedHeight,
      state.toIndex,
    )
    // console.debug(JSON.stringify(this.state.listState, null, 2))

    console.debug('get next state: did update')
    this.setState({
      toIndex: getToIndexForNextRender(this.props, state),
      skippedHeight,
      nCalculated,
      maxCalculatedHeight,
    })
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeHandler!)
    this.onScroll.cancel()
  }

  private createResizeHandler() {
    let prevWidth = window.innerWidth
    let prevHeight = window.innerHeight

    this.onResizeHandler = () => {
      this.onResize(prevWidth, prevHeight)
      prevWidth = window.innerWidth
      prevHeight = window.innerHeight
    }
  }

  private onResize(prevWidth: number, prevHeight: number) {
    if (prevWidth !== window.innerWidth) {
      this.state.itemStates.forEach(itemState => {
        if (itemState) {
          itemState.stale = itemState.height > 0
        }
      })

      const { listState } = this.state
      listState.status = Calculated.Nothing
      listState.nSkipped = listState.nRendered = 0
      listState.calculatedHeight = 0

      const scrollHeight = calculateScrollHeight(this.getListEl())
      console.debug('get next state: resize width')
      this.setState({
        maxCalculatedHeight: 0,
        nCalculated: 0,
        scrollHeight,
        skippedHeight: 0,
        toIndex: getToIndexForNextRender(this.props, this.state, scrollHeight),
      })
    } else {
      const { listState } = this.state
      // TODO: optimise this further
      if (listState.status > Calculated.Skipped) {
        listState.status = Calculated.Skipped
      }
      listState.nRendered = 0
      listState.calculatedHeight = this.state.skippedHeight

      const scrollHeight = calculateScrollHeight(this.getListEl())
      console.debug('get next state: resize height')
      this.setState({
        scrollHeight,
        toIndex: getToIndexForNextRender(this.props, this.state, scrollHeight),
      })
    }
  }

  // update this.state.{itemStates,listState} for most recently rendered items
  // and return the total height of the skipped elements
  private processRenderState(skippedHeight: number, toIndex: number): RenderChanges {
    const listEl = this.getListEl()!
    const {
      listState: { nRendered, nSkipped },
      itemStates,
      scrollHeight,
      listState,
    } = this.state

    let scrollOffset = window.scrollY

    let { calculatedHeight } = listState
    let newMaxHeight = calculatedHeight

    const { bufferSize } = this.props
    const { length } = itemStates
    let i = nRendered + nSkipped
    for (; i < length; ++i) {
      const itemState = itemStates[i]!

      // keep on processing items up to toIndex, after that stop when the first element with
      // no height or a stale height is found
      if (
        i >= toIndex &&
        (listState.status === Calculated.Rendered ||
          !itemState ||
          !itemState.height ||
          itemState.stale)
      ) {
        break
      }

      const { stale, height: prevHeight } = itemState
      if (stale || !prevHeight) {
        const elIdx = i - nSkipped
        const el = listEl.childNodes[elIdx]! as HTMLElement

        itemState.height = el.getBoundingClientRect().height
        itemState.stale = false
        console.debug('recalculated', i - nSkipped, listEl.childNodes[i - nSkipped])
      }

      // calculate bottom distance using top of list element
      const distanceFromBtm = calculatedHeight - (scrollOffset + scrollHeight)

      if (distanceFromBtm > bufferSize) {
        console.debug('off bottom', i - nSkipped, listEl.childNodes[i - nSkipped])
        listState.status = Calculated.Rendered
        newMaxHeight += itemState.height
        continue
      }

      // calculate top distance using bottom of list element
      calculatedHeight += itemState.height
      newMaxHeight = calculatedHeight
      const distanceToTop = scrollOffset - calculatedHeight

      if (distanceFromBtm > 0) {
        console.debug('in bottom margin', i - nSkipped, listEl.childNodes[i - nSkipped])
        ++listState.nRendered
        // if the height takes the top over the buffer then the full bottom buffer is known
        if (distanceFromBtm + itemState.height > bufferSize) {
          listState.status = Calculated.Rendered
        }
      } else if (distanceToTop <= 0) {
        ++listState.nRendered

        if (stale) {
          const heightIncrease = itemState.height - prevHeight
          if (distanceToTop + heightIncrease > 0) {
            // this means the item moved from the top margin into the scroll area due
            // to a height increase, scroll the window to offset this change
            window.scrollBy(0, heightIncrease)
            scrollOffset += heightIncrease
            console.debug('poking from top', i - nSkipped, listEl.childNodes[i - nSkipped])
            // not setting TopBuffer just yet as maybe another will be poking over?
            listState.status = Calculated.Skipped
            continue
          }
        }
        console.debug('inside', i - nSkipped, listEl.childNodes[i - nSkipped])
      } else {
        // item is above top of visible area
        if (stale) {
          const heightIncrease = itemState.height - prevHeight
          if (heightIncrease) {
            // compensate for the height change above the visible area by scrolling
            window.scrollBy(0, heightIncrease)
            scrollOffset += heightIncrease
          }
        }
        if (distanceToTop < bufferSize) {
          console.debug('inside top buffer', i - nSkipped, listEl.childNodes[i - nSkipped])
          ++listState.nRendered
          listState.status = Calculated.Skipped
        } else {
          console.debug('skipped', i - nSkipped, listEl.childNodes[i - nSkipped])
          ++listState.nSkipped
          listState.status = Calculated.Nothing
          skippedHeight += itemState.height
        }
      }
    }

    listState.calculatedHeight = calculatedHeight

    const itemsLength = this.props.items.length
    if (i === itemsLength) {
      listState.status = Calculated.Rendered
    } else {
      // TODO: set listState.status once here based on calculatedHeight, not in loop
    }

    const { maxCalculatedHeight } = this.state
    const hasNewMax = newMaxHeight > maxCalculatedHeight
    return {
      skippedHeight,
      maxCalculatedHeight: hasNewMax ? newMaxHeight : maxCalculatedHeight,
      nCalculated: hasNewMax ? i : this.state.nCalculated,
    }
  }

  private getListEl() {
    return this.state.listRef.current as HTMLElement | undefined
  }
}
