import 'normalize.css'
import * as React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { FeedContainer } from './feed/Feed'
import { HeaderContainer } from './header/Header'
import './index.css'
import { MenuContainer } from './menu/Menu'
import KchompBrowserRouter from './router/KchompBrowserRouter'
import { SearchContainer } from './search/Search'
import { makeStore, Store } from './store'
import { AuthForm } from './user/AuthForm'
import { AuthPasswordFormContainer } from './user/AuthPasswordForm'

const store: Store = makeStore()

// TODO: use React.memo when Route will accept it
const RegisterForm: React.SFC<{}> = () => <AuthForm register />
const RegisterPasswordForm: React.SFC<{}> = () => <AuthPasswordFormContainer register />

const App: React.SFC = React.memo(() => (
  <Provider store={store}>
    <KchompBrowserRouter>
      <React.Fragment>
        <HeaderContainer />
        <Switch>
          <Route exact path="/" component={FeedContainer} />
          <Route path="/search" component={SearchContainer} />
          <Route path="/menu" component={MenuContainer} />
          <Route path="/source/:type/:id(.*)" component={FeedContainer} />} />
          <Route exact path="/login" component={AuthForm} />} />
          <Route path="/login/password" component={AuthPasswordFormContainer} />} />
          <Route exact path="/register" component={RegisterForm} />
          <Route path="/register/password" component={RegisterPasswordForm} />} />
        </Switch>
      </React.Fragment>
    </KchompBrowserRouter>
  </Provider>
))

export default App
