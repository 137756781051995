import { map } from 'rxjs/operators'

import { FeedItem } from '../../feed/FeedItem'
import { SourceLocator, SourceSubreddit } from '../../sources/Source'
import { rxFetch } from '../../util/fetch'

export const searchReddit = (search: string) => {
  return rxFetch(`https://www.reddit.com/search/.json`, { q: search, type: 'sr' }).pipe(
    map(response => {
      const results = ((response.data && response.data.children) || []) as any[]
      return results.map(r => r.data).map(
        (result: any): SourceSubreddit => {
          return {
            type: 'reddit',
            iconImg: result.icon_img,
            iconSize: result.icon_size,
            title: result.title.replace('&amp;', '&'),
            name: result.url.replace(/\/$/, ''),
            id: result.url,
          }
        },
      )
    }),
  )
}

export const fetchRedditFeed = (sourceLocator: SourceLocator) =>
  rxFetch(`https://www.reddit.com/${sourceLocator.id}.json`).pipe(
    map(response => {
      try {
        const posts = (response.data && response.data.children) || []
        return posts.map(
          ({ data: post }: any): FeedItem => {
            const { id, url, title } = post
            const date = post.created_utc * 1000
            return { id, url, title, date }
          },
        )
      } catch (e) {
        console.warn('issue parsing reddit posts', e)
        return []
      }
    }),
  )
