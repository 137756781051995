import { EMPTY } from 'rxjs'
import { catchError, ignoreElements } from 'rxjs/operators'

import { rxFetch } from './fetch'

export const apiErrorHandler = (path: string) =>
  catchError((e: any) => {
    console.warn(path, e)
    return EMPTY
  })

export const apiCall = (path: string, body: object | any[], method = 'PUT') =>
  rxFetch(`${process.env.REACT_APP_API}${path}`, undefined, {
    method,
    body: JSON.stringify(body),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
  })

// Try to put to API, log errors and ignore response
export const unimportantApiCall = (path: string, body: object | any[], method = 'PUT') =>
  apiCall(path, body, method).pipe(
    apiErrorHandler(path),
    ignoreElements(),
  )
