import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { SubscribeButtonContainer } from '../search/SubscribeButton'

import { Source } from './Source'

interface Props {
  subscribed: boolean
  source: Source
  color?: string
}

export const SourceLink: React.SFC<{ source: Source; subscribed?: boolean }> = ({
  children,
  source,
  subscribed,
}) => {
  const url = `/source/${source.type}/${encodeURIComponent(source.id)}`
  const queryParams = !subscribed && source.type !== 'reddit' ? `?name=${source.name}` : ''
  return <Link to={`${url}${queryParams}`}>{children}</Link>
}

const SourceListItem = styled.li`
  > a {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #eee;
  }
`

const SourceComponent: React.SFC<Props> = ({ source, subscribed, color = 'white' }) => (
  <SourceListItem key={source.id} style={{ borderLeft: `7px solid ${color}` }}>
    <SourceLink source={source} subscribed={subscribed}>
      <article>
        <h2>
          <span className="type">{source.type}</span>
          <span>{!source.title ? null : <span className="name">{source.name}</span>}</span>
        </h2>
        <p>{source.title || source.name}</p>
      </article>
      <SubscribeButtonContainer source={source} subscribed={!!subscribed} />
    </SourceLink>
  </SourceListItem>
)

export default SourceComponent
