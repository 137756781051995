import { combineReducers } from 'redux'

import { Action } from '../store'
import { isUserAction } from '../user/user.reducer'
import { buildActions, createAction } from '../util/actions'

import Subscription from './Subscription'

export const [subscriptionActions, isSubscriptionAction] = buildActions({
  add: createAction('[Subscriptions] add')<Subscription>(),
  remove: createAction('[Subscriptions] remove')<string>(),
  set: createAction('[Subscriptions] set')<Subscription[]>(),
  // like set but when the subscriptions are loaded from local storage
  load: createAction('[Subscriptions] load')<Subscription[]>(),
})

export interface SubscriptionsState {
  list: Subscription[]
  loaded: boolean
}

export default combineReducers<SubscriptionsState, Action>({
  list: (state: Subscription[] = [], action: Action) => {
    if (isSubscriptionAction.add(action)) {
      return [...state, action.payload]
    } else if (isSubscriptionAction.remove(action)) {
      return state.filter(sub => sub.id !== action.payload)
    } else if (isSubscriptionAction.set(action) || isSubscriptionAction.load(action)) {
      return action.payload
    } else if (isUserAction.logout(action)) {
      return []
    } else {
      return state
    }
  },

  loaded: (state = false, action: Action) => state || isSubscriptionAction.load(action),
})
