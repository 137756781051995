import { empty, merge } from 'rxjs'
import { map, scan } from 'rxjs/operators'

import { Source } from '../sources/Source'

import { searchReddit } from './api/reddit'
import { searchTvMaze } from './api/tv-maze'
import { searchWebFeeds } from './api/webfeed'
import { searchActions } from './search.reducer'

const mergeSearchResults = (results: Source[], newResults: Source[]) => {
  if (!newResults.length) {
    return results
  }

  if (newResults[0].id === 'webfeed') {
    return newResults.concat(results)
  }

  const nonWebfeedIdx = results.findIndex(result => result.type !== 'webfeed')
  if (nonWebfeedIdx === -1) {
    return results.concat(newResults)
  }

  // first come the webfeeds
  const endResults = results.slice(0, nonWebfeedIdx)

  // then splice the rest together
  const seen = new Set<string>()

  for (let i = nonWebfeedIdx; i < results.length && newResults.length; ++i) {
    const result = results[i]
    const { type } = result
    if (seen.has(type)) {
      endResults.push(newResults.shift()!)
      if (!newResults.length) {
        // run out of new results, append the rest of the results
        return endResults.concat(results.slice(i))
      }

      seen.clear()
    }
    seen.add(type)
    endResults.push(result)
  }

  // there are remaining new results
  return endResults.concat(newResults)
}

const runSearch = (search: string) => {
  if (search.length < 3) {
    return empty()
  }

  return merge(searchReddit(search), searchTvMaze(search), searchWebFeeds(search)).pipe(
    scan(mergeSearchResults, []),
    map(searchActions.results),
  )
}

export default runSearch
