export interface SourceLocator {
  // url or other identifier
  id: string
  type: string
}

interface AbstractSource extends SourceLocator {
  id: string
  // readable name, e.g. name of TV show or subreddit
  name: string
  // e.g. /r/kittens
  title?: string
}

export interface SourceSubreddit extends AbstractSource {
  type: 'reddit'
  iconImg?: string
  iconSize?: [number, number]
}

export interface SourceTvMaze extends AbstractSource {
  type: 'tvmaze'
  images?: { [size: string]: string }
}

export interface SourceWebFeed extends AbstractSource {
  type: 'webfeed'
}

export type Source = SourceSubreddit | SourceTvMaze | SourceWebFeed

// get subscription id from a source
export const subIdFromSource = (source: Source) => `${source.type}: ${source.id}`
