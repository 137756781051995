import { ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  first,
  ignoreElements,
  map,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators'

import { history } from '../router/KchompBrowserRouter'
import { isRouterAction } from '../router/router.reducer'
import { Action } from '../store'
import { apiCall } from '../util/api'
import { rxFetch } from '../util/fetch'

import { isUserAction, userActions } from './user.reducer'

const userBaseUrl = `${process.env.REACT_APP_API}/user`

export const userEpic = combineEpics(
  // fetch usere account on first route event
  (actions$: ActionsObservable<Action>) =>
    actions$.pipe(
      filter(isRouterAction.set),
      first(),
      switchMap(({ payload: { pathName } }) =>
        rxFetch(`${userBaseUrl}/me`, undefined, { credentials: 'include' }).pipe(
          map(
            user =>
              !!user.id
                ? userActions.gotUser({ user, login: pathName === '/logged-in' })
                : userActions.gotNoUser(),
          ),
        ),
      ),
    ),

  (actions$: ActionsObservable<Action>) =>
    actions$.pipe(
      filter(isUserAction.logout),
      exhaustMap(() => {
        return rxFetch(`${userBaseUrl}/me`, undefined, {
          credentials: 'include',
          method: 'DELETE',
        }).pipe(
          // TODO: exponential back-off
          retryWhen(errors => errors.pipe(delay(10000))),
        )
      }),
      ignoreElements(),
    ),

  (actions$: ActionsObservable<Action>) =>
    actions$.pipe(
      filter(isUserAction.tryPasswordAuth),
      exhaustMap(({ payload }) =>
        apiCall(`/user/auth/local`, payload, 'POST').pipe(
          map(user => userActions.gotUser({ user, login: true })),
          tap(() => {
            history.replace('/')
          }),
          catchError(err => of(userActions.passwordAuthFailure(err))),
        ),
      ),
    ),

  // forward user from /logged-in to home page
  (actions$: ActionsObservable<Action>) =>
    actions$.pipe(
      filter(isRouterAction.set),
      first(),
      tap(({ payload: { pathName } }) => {
        if (pathName === '/logged-in') {
          history.replace('/')
        }
      }),
      ignoreElements(),
    ),
)
