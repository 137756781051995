import { format as dateFormat } from 'date-fns'
import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../root.reducer'
import { ConnectProps } from '../util/types'

type Props = ConnectProps<typeof mapStateToProps> & {
  time: number
}

const dates = [
  { suffix: 's', size: 60 },
  { suffix: 'm', size: 60 * 60 },
  { suffix: 'h', size: 24 * 60 * 60 },
  { suffix: 'd', size: 30 * 24 * 60 * 60 },
  { suffix: 'M', size: 365 * 24 * 60 * 60 },
  { suffix: 'y', size: Infinity },
]

const Time: React.SFC<Props> = React.memo(props => {
  return <time dateTime={props.dateTime}>{props.formattedDate}</time>
})

const getFormattedDate = (now: number, time: number) => {
  const secondsAgo = Math.round((now - time) / 1000)
  const secondsDistance = Math.abs(secondsAgo)
  const dateIdx = dates.findIndex(({ size }) => secondsDistance < size)
  const { suffix } = dates[dateIdx]
  const magnitude = dateIdx === 0 ? 1 : dates[dateIdx - 1].size
  return `${Math.round(secondsAgo / magnitude)}${suffix}`
}

const mapStateToProps = (state: State, props: { time: number }) => ({
  formattedDate: getFormattedDate(state.time.now, props.time),
  dateTime: dateFormat(props.time),
})

export const TimeContainer = connect(
  mapStateToProps,
  null,
)(Time)
