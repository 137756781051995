import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../root.reducer'
import FancyList from '../shared/FancyList'
import { MainBody } from '../shared/MainBody'
import SourceComponent from '../sources/SourceComponent'
import Subscription, { selectHasNoSubscriptions } from '../subscriptions/Subscription'
import { ConnectProps } from '../util/types'
import { WelcomeContainer } from '../Welcome'

type Props = ConnectProps<typeof mapStateToProps>

const renderSubscription = ({ id, source, config }: Subscription) => (
  <SourceComponent subscribed key={id} source={source} color={config.color} />
)

const Menu: React.SFC<Props> = ({ subscriptions, hasNoSubscriptions }) => (
  <MainBody>
    {hasNoSubscriptions ? (
      <WelcomeContainer />
    ) : (
      <FancyList>{subscriptions && subscriptions.map(renderSubscription)}</FancyList>
    )}
  </MainBody>
)

const mapStateToProps = (state: State) => ({
  subscriptions: state.subscriptions.list,
  hasNoSubscriptions: selectHasNoSubscriptions(state),
})

export const MenuContainer = connect(mapStateToProps)(Menu)
