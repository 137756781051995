import { getTime } from 'date-fns'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { FeedItem } from '../../feed/FeedItem'
import { SourceLocator, SourceTvMaze } from '../../sources/Source'
import { rxFetch } from '../../util/fetch'

export const searchTvMaze = (search: string) => {
  return rxFetch('https://api.tvmaze.com/search/shows', { q: search }).pipe(
    map((json: any[]) => {
      return json.map(
        ({ show }): SourceTvMaze => {
          return {
            type: 'tvmaze',
            id: show.id.toString(),
            // url: show.url,
            name: show.name,
            images: show.image,
          }
        },
      )
    }),
  )
}

export const fetchTvMazeFeed = (sourceLocator: SourceLocator): Observable<FeedItem[]> =>
  rxFetch(`https://api.tvmaze.com/shows/${sourceLocator.id}/episodes`).pipe(
    map(tvMazeResponse => {
      try {
        return tvMazeResponse.map((response: any) => {
          const id: string = response.id.toString()
          const url: string = response.url
          const date = getTime(response.airstamp)

          const season = response.season.toString().padStart(2, '0')
          const episode = response.number.toString().padStart(2, '0')
          const title = `S${season}E${episode} ${response.name}`
          return { id, url, title, date }
        })
      } catch (e) {
        console.warn('issue parsing tv maze posts', e)
        return []
      }
    }),
  )
