import Dexie from 'dexie'
import { min, reduce, values } from 'ramda'

import colors from '../sources/colors'

import Subscription from './Subscription'

class SubscriptionDexie extends Dexie {
  public subscriptions!: Dexie.Table<Subscription & { index: number }, string>

  constructor() {
    super('kchomp.subscriptions')
    this.version(1).stores({ subscriptions: 'id' })
  }
}

// wrap SubscriptionDexie, maintains insertion order of subscriptions
export class SubscriptionDb {
  private dexieTable = new SubscriptionDexie()

  // mapping from id to order in table
  private orderMapping = new Map<string, number>()
  private nextIndex = 0

  public async delete(ids: string[]) {
    ids.forEach(id => {
      this.orderMapping.delete(id)
    })
    await this.dexieTable.subscriptions.bulkDelete(ids)
  }

  public async add(subscriptions: Subscription[]) {
    await this.dexieTable.subscriptions.bulkAdd(
      subscriptions.map(subscription => {
        const index = this.nextIndex++
        this.orderMapping.set(subscription.id, index)
        return {
          ...subscription,
          index,
        }
      }),
    )
  }

  public async load(): Promise<Subscription[]> {
    this.orderMapping.clear()
    this.nextIndex = 0
    // get subscriptions, order by index
    const subscriptions = (await this.dexieTable.subscriptions.toArray()).sort(
      (a, b) => a.index - b.index,
    )

    // build orderMapping, removing index from all subscriptions
    subscriptions.forEach(subscription => {
      const { index } = subscription
      this.orderMapping.set(subscription.id, index)
      this.nextIndex = index + 1
      delete subscription.index
    })

    return subscriptions
  }
}

// gets the least used color, looping back when necessary
export const getNextColor = (subscriptions: Subscription[]) => {
  const takenCount = {} as { [k: string]: number }
  subscriptions.forEach(s => {
    const { color } = s.config
    takenCount[color] = (takenCount[color] || 0) + 1
  })

  let untakenColor = colors.find(color => !takenCount[color])
  if (!untakenColor) {
    const minCount = reduce(min, Infinity, values(takenCount))
    untakenColor = Object.keys(takenCount).find(color => takenCount[color] === minCount)
  }

  return untakenColor as string
}

export default SubscriptionDb
