import { createSelector } from 'reselect'

import { State } from '../root.reducer'
import { Action } from '../store'
import { buildActions, createAction } from '../util/actions'

export const [routerActions, isRouterAction] = buildActions({
  set: createAction('[Router] set pathname')<RouterState>(),
})

export const selectPageName = createSelector(
  (state: State) => state.router.pathName,
  pathName => pathName.substr(1).replace(/\/.*/, ''),
)

export interface RouterState {
  pathName: string
  queryString?: { [k: string]: string }
}

export default (state = { pathName: '/' }, action: Action): RouterState =>
  isRouterAction.set(action) ? action.payload : state
