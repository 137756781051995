import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../root.reducer'
import { MainBody } from '../shared/MainBody'
import { ConnectProps } from '../util/types'

import { userActions } from './user.reducer'

const Body = styled(MainBody)`
  display: flex;
  justify-content: center;

  > form {
    display: flex;
    flex-direction: column;
    width: 19rem;
    background-color: white;
    padding: 2rem 3rem;
  }
`

const Input = styled.input`
  text-align: middle;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;

  &:focus {
    outline: 0;
    border-bottom: 1px solid #777;
  }

  &::placeholder {
    color: #aaa;
    font-size: 90%;
  }
`

const SubmitButton = styled.button`
  background-color: #ffc0cb;
  padding: 0.5rem 0;
  margin-top: 0.3rem;

  &:hover,
  &:focus {
    background-color: #fab0bb;
  }
`

const ErrorMessage = styled.div`
  margin-top: 0.35rem;
  font-size: 0.8rem;
  color: #c33;
`

type Props = ConnectProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  register?: boolean
}

interface ComponentState {
  username: string
  password: string
  error?: string
  hasSubmitted: boolean
  hideAuthError: boolean
}

const missingValueError = 'Please enter a username and password'

export class AuthPasswordForm extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      hasSubmitted: false,
      error: missingValueError,
      hideAuthError: false,
    }
  }

  public render() {
    const { register, pendingAuth, authError } = this.props
    const { hasSubmitted, error, hideAuthError } = this.state

    const buttonText = register
      ? pendingAuth
        ? 'Registering'
        : 'Register'
      : pendingAuth
        ? 'Logging in'
        : 'Login'

    const displayError = (hideAuthError || pendingAuth ? null : authError) || error

    return (
      <Body>
        <form onSubmit={this.onSubmit}>
          <Input
            type="text"
            placeholder="username"
            autoComplete="username"
            onChange={this.usernameChanged}
          />
          <Input
            type="password"
            placeholder="password"
            autoComplete="current-password"
            onChange={this.passwordChanged}
          />
          <SubmitButton disabled={pendingAuth} type="submit">
            {buttonText}
          </SubmitButton>
          {!hasSubmitted || !displayError ? null : <ErrorMessage>{displayError}</ErrorMessage>}
        </form>
      </Body>
    )
  }

  private usernameChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const username = event.currentTarget.value
    this.setState({ username })
    this.validateEntries(username, this.state.password)
  }
  private passwordChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const password = event.currentTarget.value
    this.setState({ password })
    this.validateEntries(this.state.username, password)
  }

  private validateEntries(username: string, password: string) {
    if (!username || !password) {
      this.setState({ error: missingValueError })
    } else if (username.length < 4) {
      this.setState({ error: 'Username must be more than four characters long' })
    } else if (/\s{2,}/.test(username)) {
      this.setState({ error: 'Username must not contain more than one space in a row' })
    } else if (/\s$/.test(username)) {
      this.setState({ error: 'Username must not end in a space' })
    } else {
      this.setState({ error: undefined })
    }

    this.setState({ hideAuthError: true })
  }

  private onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.setState({ hasSubmitted: true })
    if (this.state.error) {
      return
    }
    this.setState({ hideAuthError: false })

    const { username, password } = this.state
    const payload = { username, password, register: this.props.register || false }
    this.props.tryPasswordAuth(payload)
  }
}

const mapStateToProps = ({ user: { pendingAuth, authError } }: State) => ({
  pendingAuth,
  authError,
})

const mapDispatchToProps = {
  tryPasswordAuth: userActions.tryPasswordAuth,
}

export const AuthPasswordFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPasswordForm)
