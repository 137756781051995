import { Observable } from 'rxjs'

interface Params {
  [k: string]: string
}

export const rxFetch = <T = any>(
  url: string,
  params?: Params,
  opts: RequestInit = {},
): Observable<T> =>
  new Observable(observer => {
    let controller: AbortController | undefined

    const fetchAndGetJson = async () => {
      try {
        if (controller) {
          opts.signal = controller.signal
        }

        const queryParamProps = !params
          ? []
          : Object.keys(params).map(
              propName => `${propName}=${encodeURIComponent(params[propName])}`,
            )
        const queryParamString = queryParamProps.length ? '?' + queryParamProps.join('&') : ''

        const resp = await fetch(url + queryParamString, opts)
        const contentType = resp.headers.get('content-type')
        const isGoodStatus = resp.status >= 200 && resp.status < 300

        if (contentType && contentType.indexOf('application/json') !== -1) {
          const json = await resp.json()
          if (isGoodStatus) {
            observer.next(json)
          } else {
            observer.error(json)
          }
        } else {
          const text = await resp.text()
          if (isGoodStatus) {
            observer.next((text as {}) as T)
          } else {
            observer.error((text as {}) as T)
          }
        }
      } catch (e) {
        observer.error(e)
      }
    }

    try {
      controller = new AbortController()
    } catch (e) {}

    fetchAndGetJson()
    return () => {
      if (controller) {
        controller!.abort()
      }
    }
  })
