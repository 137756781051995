import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../root.reducer'
import { ConnectProps } from '../util/types'

import { searchActions } from './search.reducer'

const Form = styled.form`
  display: flex;
  justify-content: center;
  height: 2.5rem;
  padding: 0.4rem 0;
`

const Input = styled.input`
  width: 80%;
  padding: 0 0.4rem;
  &::placeholder {
    font-size: 0.9rem;
  }
`

type Props = ConnectProps<typeof mapStateToProps, typeof mapDispatchToProps>

class SearchForm extends React.PureComponent<Props> {
  public readonly inputRef = React.createRef<HTMLInputElement>()

  public render() {
    return (
      <Form onSubmit={this.forceSearch}>
        <Input
          placeholder="Enter a subreddit, tv show or website domain"
          // typings not supported by.. styled-components? yet
          ref={this.inputRef as any}
          type="text"
          onChange={this.searchChanged}
          value={this.props.searchString || ''}
        />
      </Form>
    )
  }

  public componentDidMount() {
    this.inputRef.current!.focus()
  }

  private forceSearch = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.forceSearch()
  }

  private searchChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    this.props.searchFor(value)
  }
}

const mapStateToProps = (state: State) => ({
  searchResults: state.search.results,
  searchString: state.search.searchString,
})
const mapDispatchToProps = {
  searchFor: searchActions.setTerms,
  forceSearch: searchActions.force,
}

export const SearchFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchForm)
