import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../root.reducer'
import { ConnectProps } from '../util/types'

const ProgressDiv = styled.div`
  position: relative;
  width: 100%;
  height: 2px;

  div {
    position: absolute;
    top: 0;

    &.background {
      height: 1px;
      background-color: #ddd;
      z-index: 2;
      width: 100%;
    }

    &.outstanding {
      height: 2px;
      background-color: #001;
      z-index: 3;
      transition: width 0.3s;
    }
  }
`

type Props = ConnectProps<typeof mapStateToProps>

const ProgressIndicator: React.SFC<Props> = React.memo(({ width }) => {
  return (
    <ProgressDiv>
      <div className="background" />
      <div className="outstanding" style={{ width }} />
    </ProgressDiv>
  )
})

const mapStateToProps = ({ progress: { completeCount, totalCount } }: State) => ({
  width: totalCount === 0 ? '0%' : `${(completeCount / totalCount) * 100}%`,
})

export const ProgressIndicatorContainer = connect(mapStateToProps)(ProgressIndicator)
