import { buildActionTests, createAction } from './util/actions'

// child reducers/epics
import { FeedState } from './feed/feed.reducer'
import { ProgressState } from './progress/progress.reducer'
import { RouterState } from './router/router.reducer'
import { SearchState } from './search/search.reducer'
import { SubscriptionsState } from './subscriptions/subscription.reducer'
import { TimeState } from './time/time.reducer'
import { UserState } from './user/user.reducer'

export const rootActions = {
  toggleSearch: createAction('[Kchomp] toggle search')(),
  toggleMenu: createAction('[Kchomp] toggle menu')(),
}

export const isRootAction = buildActionTests(rootActions)

export interface State {
  router: RouterState
  time: TimeState
  search: SearchState
  subscriptions: SubscriptionsState
  feed: FeedState
  user: UserState
  progress: ProgressState
}

// rootReducer and rootEpic are simply a combination of the child reducers/epics so they
// are defined in store.ts to avoid creating circular import references
